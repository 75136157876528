@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Text:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

@import "~katex/dist/katex.min.css";


.wrapper-echarts,
.echarts-for-react {
  box-sizing: border-box;
  font-optical-sizing: 'auto';
}


.wrapper-echarts {
  width: 95%;

  @media screen and (min-width: 1920px) {
    max-width: 95%;
  }

  @media screen and (min-width: 1600px) {
    width: 800px;
  }

  @media screen and (min-width: 1550px) {
    width: 580px;
  }

  @media screen and (max-width: 1549px) {
    width: 500px;
    max-width: 95%;
  }

  @media screen and (max-width: 1440px) {
    width: 95%;
    max-width: 95%;
  }
}

.echarts-for-react {
  width: 95%;

  @media screen and (min-width: 1920px) {
    max-width: 95%;
  }

  @media screen and (min-width: 1600px) {
    width: 800px;
  }

  @media screen and (min-width: 1550px) {
    width: 500px;
    max-width: 95%;
  }

  @media screen and (max-width: 1549px) {
    width: 500px;
    max-width: 95%;
  }

  @media screen and (max-width: 1440px) {
    width: 95%;
    max-width: 95%;
  }

}

.h1-landing-page {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.h1-dashboard {
  font-size: 3rem;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
}

.h2-dashboard {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.h3-dashboard {
  font-size: 1.125rem;
  margin: 0;
  padding: 0;
}

.h6-dashboard {
  font-size: 1.1rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.header-btn-dashboard {
  width: 155px;
  color: var(--joy-palette-primary-700);

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    background-color: var(--joy-palette-primary-100);
  }
}

.visible-component {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.hidden-component {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.flex {
  display: flex;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.all-width {
  width: 100%;
}

.btn-admin {
  width: 17rem;
}


.mapboxgl-popup-content {
  padding: 0;
  width: fit-content;
}

.popup-div {
  display: flex;
  flex-direction: column;
  /* Use a common sans-serif font */
  color: #333;
  /* Darker text color */
  margin: 0%;
  padding: 0%;
  text-wrap: nowrap;
}

.popup-details {
  display: flex;
  margin: 0%;
  padding: 0%;
  flex-direction: column;
  /* Adjust as needed */
}

.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Align items vertically */
  gap: 20px;
  margin: 0%;
  padding: 0%;
}

.metric-head {
  margin: 0%;
  padding: 0%;
}

.metric-value {
  /* Increase font size */
  margin: 0%;
  padding: 0%;
  color: black;
  padding: 0%;
}

/* Remove close button on mapbox popup */
.mapboxgl-popup-close-button {
  display: none;
}

.poly-value {
  font-size: 25px;
  margin: 0%;
  padding: 0%;
  gap: 0px;
  margin-top: 0%;
  margin-bottom: 0%;
  color: black;
}

.detail-row>p {
  margin: 0%;
  padding: 5px 0px;
}

.mapboxgl-popup {
  z-index: 2;
}


/* Map slider CSS */
.MuiSlider-valueLabel {
  padding: 0%;
  margin: 0%;
  background-color: transparent !important;
}

.MuiSlider-markLabel {
  color: rgb(255, 255, 255);
}

.MuiSlider-mark {
  background-color: rgb(29, 29, 29);
  width: 5px;
  height: 5px;
}

.MuiSlider-track {
  color: rgb(253, 253, 253);
  background-color: rgb(216, 216, 216);
}

.MuiSlider-rail {
  background-color: rgba(207, 207, 207, 0.8);
  height: 2px;
}


// Styles for Ground truth popup form

.form-container {
  padding: 0.5em;
  margin: auto;
  width: 300px;
}

.form-group {
  margin-bottom: 0.8em;
  display: flex;
}

.form-group input {
  width: 100%;
  border: none;
  border-radius: 5px;
}

#landcover {
  width: 100%;
  height: 24px;
  border: none;
  background-color: #f8fafc;
  border-radius: 5px
}


.btn-group {
  display: flex;
  justify-content: space-between;
}

.submit-btn {
  padding: 0.5em 2em;
  background-color: #166C5D !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.cancel-btn {
  padding: 0.5em 2em;
  background-color: #6c757d !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.error {
  color: red;
}


table {
  width: auto !important;
}

td {
  white-space: nowrap;
}

// draw tools css
.mapboxgl-ctrl-group {
  margin: 0% !important;
  border-radius: 0% !important;
}

// isolate the file upload button from the draw buttons
.mapboxgl-ctrl.mapboxgl-ctrl-group:has(.file-upload-button) {
  margin-bottom: 10px !important;
  border-radius: 5px !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group:has(.custom-button) {
  border-radius: 5px 5px 0px 0px !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group:has(.mapbox-gl-draw_trash) {
  margin-bottom: 10px !important;
  border-radius: 0px 0px 5px 5px !important;
}

.mapboxgl-ctrl-top-left {
  margin: 10px !important;
  border-radius: 20px !important;
}

table {
  min-width: 700px;
}

// custom tab styles
.tab {
  overflow: hidden;
  background-color: #f1f1f1;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 50px;
  transition: 0.3s;
}


.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  border-bottom: 3px solid #166C5D;
  background-color: #166c5e12;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.dual-background-text {
  color: #FFFFFF;
  text-shadow: #000000 1px 1px 5px;
}